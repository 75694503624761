(function (module) {

    var assignMentorCtrl = function ($scope, alertSvc, commissionAbbreviations, helperSvc, pevc, searchOptions, $uibModalInstance, trainingSvc) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Assign Mentor';
        model.searchOptions = { trainingYear: searchOptions.trainingYear, societyId: pevc.societyId };
        model.currentPevc = pevc;
        model.commissionNames = commissionAbbreviations;
        model.selectedMentor = { mentor: null };
        model.firstSave = true;

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.firstSave) {
                model.firstSave = false;
                trainingSvc.addMentees(model.selectedMentor.mentor.trainingMentorId, [pevc.personId]).then(function () {
                    trainingSvc.getPEVCTrainingSearch(searchOptions)
                    alertSvc.addAlertSuccess("Mentor succesfully assigned.");
                    $uibModalInstance.close();
                });
            }
        }

        var activate = function () {
            var dataArray = [];
            var trainingMentors = {
                dataHolder: model,
                dataLocationName: 'mentorList',
                svcCallback: trainingSvc.getTrainingMentors,
                svcCallbackArguments: [model.searchOptions],
                odataResource: true
            }
            dataArray.push(trainingMentors);

            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;
                model.dataIsReady = true;
            });
        }();
    };

    module.controller('assignMentorCtrl', assignMentorCtrl);

}(angular.module('training')));
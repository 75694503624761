(function (module) {

    /* nominationDirectives includes the following:
        - commissionerNomination
    */

    var templateRoot = '/apps/nomination/templates/widget/';

    module.directive('commissionerNomination', function ($uibModal, volunteerHelperSvc, oauth, nominationSvc, codeSvc, helperSvc,
                                                         alertSvc, messageSvc, messageTemplateTypes, commissionTypes) {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: templateRoot + 'commissionerNomination.html',
            scope: {
                admin: '=?admin',
                committee: '=?committee'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.isAdmin = attrs.admin ? (attrs.admin.toLowerCase() == 'true') : oauth.isAdmin();
                scope.isCommittee = attrs.committee ? (attrs.committee.toLowerCase() == 'true') : false;
                scope.isAlternate = false;

                scope.filters = {
                    currentSociety: null,
                    currentCommission: null,
                    currentCommissionerStatus: null,
                    allSelected: false
                }

                scope.nominationsClosedDate = helperSvc.getCommissionerNominationsClosedDate(); 4
                // Nominations never closed due to a bug in calculating closed date.
                // Need to determine opening date before we prevent users from doing last minute nominations
                scope.nominationsClosed = false; // scope.nominationsClosedDate < new Date(); // && !scope.isAdmin // Should we keep it open for admin?

                scope.roles = nominationSvc.roles;
                scope.nominationStatuses = nominationSvc.nominationStatuses;

                var commissionerStatuses = {
                    RENEWABLE: 0,
                    NOT_RENEWABLE: 1,
                    NEW_NOMINEE: 2
                }

                scope.commissionerStatusOptions = [
                    { name: 'Renewable', value: commissionerStatuses.RENEWABLE },
                    { name: 'Not Renewable', value: commissionerStatuses.NOT_RENEWABLE },
                    { name: 'New Nominee', value: commissionerStatuses.NEW_NOMINEE }
                ]

                scope.isIneligible = nominationSvc.isIneligible;

                scope.commissionerFilter = function (commissioner) {
                    if (scope.filters.currentSociety && scope.filters.currentSociety.codeKey != commissioner.societyId)
                        return false;

                    if (scope.filters.currentCommission && scope.filters.currentCommission.id != commissioner.commissionId)
                        return false;

                    if (scope.designatedOrgIds && scope.designatedOrgIds.indexOf(commissioner.commissionId) === -1)
                        return false;

                    switch (scope.filters.currentCommissionerStatus) {
                        case commissionerStatuses.RENEWABLE:
                            if (!commissioner.isRenewable || commissioner.isNewNominee)
                                return false;
                            break;
                        case commissionerStatuses.NOT_RENEWABLE:
                            if (commissioner.isRenewable || commissioner.isNewNominee)
                                return false;
                            break;
                        case commissionerStatuses.NEW_NOMINEE:
                            if (!commissioner.isNewNominee)
                                return false;
                            break;
                    }

                    return scope.isAlternate ?
                        commissioner.roleId === scope.roles.ALTERNATE_MEMBER :
                        commissioner.roleId === scope.roles.COMMISSION_MEMBER;
                };

                scope.commissionerStatusHtml = '<ul class="semi-style">' +
                        '<li><label class="no-margin">Renewable</label>: Current commissioner eligible for renewal</li>' +
                        '<li><label class="no-margin">Not Renewable</label>: Current commissioner who has already served 5 terms</li>' +
                        '<li><label class="no-margin">New Nominee</label>: New nominee who is not a current commissioner</li>' +
                    '</ul>';

                scope.nominationStatusHtml = '<ul class="semi-style">' +
                        '<li><label class="no-margin">Waiting for Nomination</label>: Current commissioner eligible for renewal</li>' +
                        '<li><label class="no-margin">Nominated</label>: New commissioner nomination</li>' +
                        '<li><label class="no-margin">Renewed</label>: Current commissioner has been renewed for a new term</li>' +
                        '<li><label class="no-margin">Submitted</label>: Nomination has been submitted for review</li>' +
                        '<li><label class="no-margin">Approved</label>: Nomination has been accepted</li>' +
                        '<li><label class="no-margin">Disapproved</label>: Nomination has been rejected</li>' +
                        '<li><label class="no-margin">N/A</label>: Not eligible for renewal</li>' +
                    '</ul>';

                scope.nominationStatusText = [
                    '',
                    'Waiting for Nomination',
                    'Nominated',
                    'Renewed',
                    'Not Renewed'
                ];

                scope.getNominationStatusDisplayText = function (commissioner) {
                    var displayText = 'N/A';
                    if (commissioner.commissionerNominationStatusId != scope.nominationStatuses.WAITING_FOR_NOMINATION || commissioner.isRenewable) {
                        displayText = scope.nominationStatusText[commissioner.commissionerNominationStatusId];
                        if (commissioner.submittedDate) {
                            switch (commissioner.isApproved) {
                                case true:
                                    displayText += ' — Approved';
                                    break;
                                case false:
                                    displayText += ' — Disapproved';
                                    break;
                                default:
                                    displayText += ' — Submitted';
                                    break;
                            }
                        }
                    }

                    return displayText;
                }

                scope.openBioModal = volunteerHelperSvc.openBioModal;

                scope.openPerformanceAppraisal = function (commissioner) {
                    var personIdParam = {
                        name: 'ParamPersonId',
                        value: commissioner.personId
                    };

                    // Look at last three full cycle years (not appraisal data exists yet for current cycle)
                    var currentCycleYear = helperSvc.getAcademicYear() - 1;

                    var fromReviewYearParam = {
                        name: 'ParamFromReviewYear',
                        value: currentCycleYear - 3
                    };

                    var toReviewYearParam = {
                        name: 'ParamToReviewYear',
                        value: currentCycleYear
                    };

                    var parameters = [personIdParam, fromReviewYearParam, toReviewYearParam];

                    getReportModal("Performance Appraisal Individual Result", parameters);
                    
                    function getReportModal(reportName, params) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: '/Apps/report/templates/preview.html',
                            size: 'xl',
                            controller: 'previewCtrl',
                            resolve: {
                                reportName: function () { return reportName; },
                                inparams: function () { return params; }
                            }
                        });
                        return modalInstance;
                    };
                }

                scope.toggleCommissioners = function (isAlternate) {
                    isAlternate = isAlternate || false;
                    if (scope.isAlternate == isAlternate) return;
                    scope.isAlternate = !scope.isAlternate;
                };

                scope.setCurrentCommission = function () {
                    // Limit options to designated commissions (UNLESS user is none is specified, in which case, allow access to all) 
                    if (!scope.isCommittee || scope.isAdmin) return;
                    var COMMISSION_NOMINATING_COMMITTEE = 1568;
                    var nominatingCommitteeRoles = oauth.getUserRolesByOrganizationId(COMMISSION_NOMINATING_COMMITTEE);
                    // No designated commission?  Assume for now that this means they have access to all of them.
                    if (!nominatingCommitteeRoles || !nominatingCommitteeRoles.length) return;
                    var designatedOrgIds = [];
                    angular.forEach(nominatingCommitteeRoles, function (role) {
                        var theseDesignations = role.oAuthOrganizationUserDesignations || [];
                        angular.forEach(theseDesignations, function (designation) {
                            designatedOrgIds.push(designation.designationId);
                        });
                    });
                    // Use designated commissions to filter
                    scope.designatedOrgIds = designatedOrgIds.length > 0 ? designatedOrgIds : null;
                    // Assume no designations means they are a chair or some other role that can see all
                    if (!scope.designatedOrgIds) return;
                    // Limit options to designated commissions
                    scope.commissionOptions = scope.commissionOptions.filter(function (commission) {
                        return designatedOrgIds.indexOf(commission.id) > -1;
                    });
                    // If only one commission is available, set filter's current commission to that option
                    if (scope.commissionOptions.length === 1) {
                        scope.filters.currentCommission = scope.commissionOptions[0];
                    }
                }

                scope.setCurrentSociety = function () {
                    if (!scope.isAdmin) {
                        var societyOptions = nominationSvc.getSocietyOptionsByCurrentUser(scope.societyOptions);
                        if (societyOptions && societyOptions.length) {
                            scope.societyOptions = societyOptions;
                            scope.filters.currentSociety = scope.societyOptions[0];
                        }
                    }
                }

                scope.editRenewal = function (commissionerNomination) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/nomination/templates/modal/renewalEdit.html',
                        size: 'md',
                        controller: 'renewalEditCtrl',
                        resolve: {
                            commissionerNomination: function () { return commissionerNomination; }
                        }
                    });
                };

                scope.addCommissioner = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/nomination/templates/modal/addCommissioner.html',
                        size: 'md',
                        controller: 'addCommissionerCtrl',
                        resolve: {
                            roleId: function () { return scope.isAlternate ? scope.roles.ALTERNATE_MEMBER : scope.roles.COMMISSION_MEMBER; },
                            currentSociety: function () { return scope.filters.currentSociety || null; },
                            isAlternate: function () { return scope.isAlternate; },
                            isAdmin: function () { return scope.isAdmin; },
                            commissionOptions: function () { return scope.commissionOptions; },
                            societyOptions: function () { return scope.societyOptions; }
                        }
                    });
                };

                scope.delete = function (commissionerNomination) {
                    alertSvc.confirmDelete(commissionerNomination.personName, deleteFunc);

                    function deleteFunc() {
                        nominationSvc.delete(commissionerNomination).then(
                            function () {
                                alertSvc.addAlertSuccess("Commissioner nomination successfully removed.");
                            },
                            function (error) {
                                console.log(error);
                                alertSvc.addAlertWarning('Commissioner nomination could not be removed at this time.');
                            });
                    }
                }

                scope.tooManyNominations = function () {
                    // Would probably have put this in template but Visual Studio converts > or < in a ng-disabled to &lt; or &gt;
                    return (scope.data.summary.commissioners.nominated > scope.data.summary.seats);
                }

                scope.submit = function () {
                    alertSvc.confirm('Are you sure you want to submit nominations for commissioners and alternates? This action cannot be undone.', submitFunc);

                    function submitFunc() {
                        var societyId = scope.filters.currentSociety.codeKey;

                        nominationSvc.submit(societyId).then(
                            function () {
                                alertSvc.addAlertSuccess("Commissioner nominations successfully submitted.");
                                sendSubmissionNotification();
                            },
                            function (error) {
                                console.log(error);
                                alertSvc.addAlertWarning('Commissioner nomination could not be submitted at this time.');
                            });
                    }
                }

                function sendSubmissionNotification() {
                    var messageTemplateId = messageTemplateTypes.COMMISSIONERNOMINATIONSSUBMITTED;

                    var recipients = ['commissionernomination@abet.org'];  

                    var messageParams = [
                        {
                            messageParameterName: 'SocietyAbbreviatedName',
                            messageParameterValue: scope.filters.currentSociety.codeName
                        }
                    ];

                    messageSvc.buildAndSendMessage(recipients, [], messageTemplateId, messageParams);
                }

                scope.resetSelection = function () {
                    // Only admins ever see select checkbox
                    if (!scope.isAdmin) return; 
                    scope.filters.allSelected = false;
                    angular.forEach(scope.data.nominations, function (commissioner) {
                        commissioner.isSelected = undefined;
                    });
                }

                scope.selectAll = function (mode) {
                    // Mode can be 'commisssioners' or 'alternates'; if missing, nothing should be selected/deselected
                    var commissionersOnly = (mode === 'commissioners');
                    var alternatesOnly = (mode === 'alternates'); 
                    var selected = commissionersOnly ? scope.filters.allCommissionersSelected : scope.filters.allAlternatesSelected;
                    // Set selection of visible items
                    var selectableNominations = scope.data.nominations.filter(function (commissioner) {
                        return commissioner.submittedDate && commissioner.isApproved === null && scope.commissionerFilter(commissioner) &&
                               (!commissionersOnly || commissionersOnly && commissioner.roleId === scope.roles.COMMISSION_MEMBER) &&
                               (!alternatesOnly || alternatesOnly && commissioner.roleId === scope.roles.ALTERNATE_MEMBER);
                    });
                    angular.forEach(selectableNominations, function (commissioner) {
                        commissioner.isSelected = selected;
                    });
                }

                scope.isApproveEnabled = function () {
                    if (!scope.isAdmin)
                        return false;

                    return scope.data.nominations.some(function (nomination) {
                        return nomination.isSelected;
                    });
                }

                scope.setApprovals = function (approved) {
                    // Get list of visible items that have been selected
                    var nominations = scope.data.nominations.filter(function (commissioner) {
                        return commissioner.isSelected && commissioner.isApproved === null && commissioner.submittedDate && scope.commissionerFilter(commissioner);
                    });
                    var approvedOrDisapproved = approved ? "approved" : "disapproved";

                    nominationSvc.setApprovals(approved, nominations).then(
                        function () {
                            // Clear selection of processed nominations
                            nominations.forEach(function (commissioner) {
                                commissioner.isSelected = false;
                            });
                            alertSvc.addAlertSuccess("Commissioners nominations successfully " + approvedOrDisapproved + ".");
                        },
                        function (error) {
                            console.log(error);
                            alertSvc.addAlertWarning("Commissioner nominations could not be " + approvedOrDisapproved + " at this time.");
                        });
                }

                scope.activate = function () {
                    scope.isDataReady = false;
                    // load data
                    scope.commissionOptions = commissionTypes;
                    scope.setCurrentCommission();
                    codeSvc.getMemberAndSupportingSocieties().then(function (data) {
                        scope.societyOptions = helperSvc.getResults(data);
                        scope.setCurrentSociety();
                        nominationSvc.getCurrentNominations(scope.filters.currentSociety && scope.filters.currentSociety.codeKey).then(function (data) {
                            scope.data = nominationSvc.data;
                            // after data is loaded
                            scope.isDataReady = true;
                        });
                    });
                }();
            }
        };
    });

    module.directive('commissionerSearch', function (nominationSvc) {
        // person object must be nested at least one level for two way binding to work i.e. attribute in 
        // view should use person="model.personData.person" or person="personData.person"
        // hoping to find a way to avoid this in the future
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'commissionerSearch.html',
            scope: {
                society: '=',
                isAlternate: '@',
                commissioner: '=?',
                required: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.noResults = false;
                scope.searchForAlternate = attrs.isAlternate === 'true' ? true : false;

                scope.searchCommissioners = function (val) {
                    var searchOptions = {
                        searchText: val,
                        societyId: scope.society ? scope.society.codeKey : null,
                        eligibleOnly: false,
                        isAlternate: scope.searchForAlternate,
                        maxResults: 0
                    }

                    return nominationSvc.searchCommissioners(searchOptions);
                };

                scope.onSelectCommissioner = function (item, model, label, event) {
                    scope.commissioner = item;
                    scope.container.selectedCommissionerName = item.personName;
                };

                scope.$watch('container.selectedCommissionerName', function () {
                    if (scope.container.selectedCommissionerName == null)
                        scope.commissioner = null;
                }, true);
            }
        };
    });

}(angular.module('nomination')));
(function (module) {
    var manageFacilitatorsCtrl = function ($uibModal, helperSvc, codeSvc, volunteerSvc, alertSvc, oauth, trainingSvc, contactRoleTypes, commissionAbbreviations) {

        var model = this;
        var selectedId = null;
        
        model.isDataReady = false;
        model.isAdmin = oauth.isAdmin();
        model.searchOptions = { commissionId: null, trainingSessionId: null }

        model.commissionNames = commissionAbbreviations;

        model.filterFacilitators = function () {
            trainingSvc.getTrainingFacilitators(model.searchOptions);
        };

        model.addFacilitators = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/addFacilitators.html',
                size: 'md',
                controller: 'addFacilitatorCtrl',
                resolve: {
                    societyOptions: function () {
                        return model.societyOptions;
                    },
                    searchOptions: function(){
                        return model.searchOptions;
                    }
                }
            });
        };

        model.assignPEVCs = function (facilitator) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/training/templates/modal/assignPEVCsFacilitator.html',
                size: 'lg',
                controller: 'assignPEVCsFacilitatorCtrl',
                resolve: {
                    facilitator: function () {
                        return facilitator;
                    },
                    searchOptions: function () {
                        return model.searchOptions;
                    }
                }
            });
        };

        model.deleteFacilitator = function (facilitator) {
            alertSvc.confirmDelete(facilitator.personDto.firstName + " " + facilitator.personDto.lastName + " (facilitator)", deleteFacilitator);

            function deleteFacilitator() {
                trainingSvc.deleteFacilitator(facilitator.trainingFacilitatorId).then(function () {
                    alertSvc.addAlertSuccess("Facilitator succesfully deleted.");
                    trainingSvc.getTrainingFacilitators(model.searchOptions);
                });
            }
        };

        model.csvHeaders = ["First Name", "Last Name", "Email", "Commission Name", "Pevcs/Observers"];
        model.csvColumns = ["firstName", "lastName", "email", "commissionName", "pevcs"];
        model.export = function () {
            model.showSpinnerExport = true;
            var exportData = [];
            var facilitators = angular.copy(model.data.trainingFacilitatorList);
            var finalList = [];

            

                angular.forEach(facilitators, function (facilitator) {

                    //get pevc list
                    var pevcsAssigned = helperSvc.getFilteredArray(model.data.pevcs, 'trainingFacilitatorId', facilitator.trainingFacilitatorId, true);
                    var observersAssigned = helperSvc.getFilteredArray(model.data.trainingObserverList, 'trainingFacilitatorId', facilitator.trainingFacilitatorId, true);
                    pevcsAssigned = pevcsAssigned.concat(observersAssigned)

                    pevsAsStrings = "";
                    angular.forEach(pevcsAssigned, function (pevc) {
                        pevsAsStrings += pevc.firstName + " " + pevc.lastName + "; "
                    })

                    var tempPevc = {
                        firstName: facilitator.personDto.firstName,
                        lastName: facilitator.personDto.lastName,
                        email: facilitator.emailAddress,
                        commissionName: model.commissionNames[facilitator.commissionId],
                        pevcs: pevsAsStrings
                    };

                    finalList.push(tempPevc);
                });

                model.showSpinnerExport = false;
                return finalList;
            
        };

       

        var activate = function () {
            model.isDataReady = true;
            var dataArray = [];
            var trainingYear = new Date(trainingSvc.data.trainingSessions[0].startDate).getFullYear();
            model.searchOptions.trainingId = trainingSvc.data.trainingSessions[0].trainingId;

            var getTrainingSessionData = {
                dataHolder: model,
                dataLocationName: 'trainingSessions',
                svcCallback: trainingSvc.getPEVCTrainingSessionsAdmin,
                svcCallbackArguments: [trainingSvc.data.trainingSessions[0].trainingId],
                odataResource: true
            };
            dataArray.push(getTrainingSessionData);

            var getCommissionData = {
                dataHolder: model,
                dataLocationName: 'trainingCommissions',
                svcCallback: codeSvc.getCommissions,
                helperCallback: helperSvc.getResults
            };
            dataArray.push(getCommissionData);


            helperSvc.getData(dataArray).then(function () {
                trainingSvc.getTrainingFacilitators(model.searchOptions).then(function (data) {
                    //this is needed for the export so no need for callback.
                    trainingSvc.getTrainingObservers(model.searchOptions);
                    model.isDataReady = true;
                    model.data = trainingSvc.data;
                });
            });
        }();
    };
    module.controller('manageFacilitatorsCtrl', manageFacilitatorsCtrl);

}(angular.module('training')));
(function (module) {

    var templateRoot = '/apps/training/templates/widget/';
    /*
        - mentor-search
        - pevc-evaluation
        - select-training-sessions
    */

    module.directive('mentorSearch', function (trainingSvc, oauth, volunteerSvc) {
        // person object must be nested at least one level for two way binding to work i.e. attribute in 
        // view should use person="scope.personData.person" or person="personData.person"
        // hoping to find a way to avoid this in the future
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'mentorSearch.html',
            scope: {
                society: '=',
                isAlternate: '@',
                mentor: '=?',
                required: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.noResults = false;
                scope.isAdmin = oauth.isAdmin();
                scope.searchForAlternate = attrs.isAlternate === 'true' ? true : false;

                scope.searchMentors = function (val) {
                    var searchOptions = {
                        personName: val,
                        societyCode: scope.society ? scope.society.codeKey : null
                    }

                    return volunteerSvc.advancedSearch(searchOptions, true);
                };

                scope.onSelectMentor = function (item, model, label, event) {
                    scope.mentor = item;
                    scope.container.selectedMentorName = item.personName;
                };

                scope.$watch('container.selectedMentorName', function () {
                    if (scope.container.selectedMentorName == null)
                        scope.mentor = null;
                }, true);
            }
        };
    });

    module.directive('facilitatorSearch', function (trainingSvc, oauth, volunteerSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'facilitatorSearch.html',
            scope: {
                facilitator: '=?',
                required: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.noResults = false;

                scope.searchFacilitators = function (val) {
                    var searchOptions = {
                        personName: val,
                    }

                    return volunteerSvc.advancedSearch(searchOptions, true);
                };

                scope.onSelectFacilitator = function (item, model, label, event) {
                    scope.facilitator = item;
                    scope.container.selectedFacilitatorName = item.personName;
                };

                scope.$watch('container.selectedFacilitatorName', function () {
                    if (scope.container.selectedFacilitatorName == null)
                        scope.facilitator = null;
                }, true);
            }
        };
    });

    module.directive('observerSearch', function (trainingSvc, oauth, personSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'observerSearch.html',
            scope: {
                observer: '=?',
                required: '@?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.container = {};
                scope.noResults = false;

                scope.searchObservers = function (val) {
                    //var searchOptions = {
                    //    personName: val,
                    //}

                    return personSvc.quickSearch(val, true);
                };

                scope.onSelectObserver = function (item, model, label, event) {
                    scope.observer = item;
                    scope.container.selectedObserverName = item.personName;
                };

                scope.$watch('container.selectedObserverName', function () {
                    if (scope.container.selectedObserverName == null)
                        scope.observer = null;
                }, true);
            }
        };
    });

    module.directive('pevcEvaluation', function (trainingSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'pevcEvaluation.html',
            scope: {
                pevc: '=?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.evaluation = {};
                scope.isDataReady = false;

                scope.getFeedback = function (evaluation, criteria, levels) {
                    var val = null;
                    levels.forEach(function (level) {
                        evaluation.assessment.criteriaOutcome.forEach(function (result) {
                            if (result.criterionId == criteria.id && result.levelId == level.id) {
                                val = JSON.parse(result.feedback).Text;
                            };
                        });
                    });
                    return val;
                };

                scope.getOverallFeedback = function (evaluation) {
                    return JSON.parse(evaluation.assessment.overallOutcome.feedback).Text;
                };

                var activate = function () {
                    trainingSvc.getPEVCEvaluationByPersonId(scope.pevc.personId, scope.pevc.trainingSessionId).then(function (data) {
                        scope.evaluation = data;
                        scope.hasAssessment = data.assessment ? data.assessment.criteriaOutcome.length > 0 : false;
                        scope.isDataReady = true;
                    });
                }();
            }
        };
    });

    module.directive('selectTrainingSession', function (trainingSvc, oauth, helperSvc, trainingStatusIds, alertSvc, membershipSvc, contactRoleTypes, messageSvc, messageTemplateTypes, currentUser) {
        // person object must be nested at least one level for two way binding to work i.e. attribute in 
        // view should use person="scope.personData.person" or person="personData.person"
        // hoping to find a way to avoid this in the future
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'selectTrainingSession.html',
            scope: {
                pevc: '=',
                trainingSessions: '=?',
                searchOptions: '=?'
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.isAdmin = oauth.isAdmin();                
                scope.registrationComplete = false;

                function setRegistrationVariables(){
                    scope.noTrainingDataAvailable = true;
                    scope.alreadyRegisteredForTraining = false; 
                    if(scope.pevc && Object.keys(scope.pevc).length > 0){
                        scope.noTrainingDataAvailable = false;
                        if(scope.pevc.trainingSessionId !== null){
                            scope.alreadyRegisteredForTraining = true;
                        }
                    }
                }

                setRegistrationVariables();
    
                scope.registrationNotAvailable = function (training) {
                    var maxSeatsByCommission = [{ commissionId: 1, seats: training.maxSeatAnsac }, { commissionId: 2, seats: training.maxSeatCac },
                        { commissionId: 3, seats: training.maxSeatEac }, { commissionId: 4, seats: training.maxSeatEtac }
                    ]

                    var registerNumbersByCommission = [{ commissionId: 1, total: training.numberOfRegisteredAnsac }, { commissionId: 2, total: training.numberOfRegisteredCac },
                        { commissionId: 3, total: training.numberOfRegisteredEac }, { commissionId: 4, total: training.numberOfRegisteredEtac }
                    ]
                    var trainingSessionLimit = maxSeatsByCommission.filter(t => t.commissionId === scope.pevc.commissionId).map(t => t.seats)[0];
                    var count = registerNumbersByCommission.filter(t => t.commissionId === scope.pevc.commissionId).map(t => t.total)[0];
                    var dateDiff = helperSvc.getDateDiff(new Date(), training.registrationEndDate, "days");
                    if (count < trainingSessionLimit && dateDiff >= -1) {//-1 because it needs to be this whole day included
                        return false;
                    } else {
                        return true;
                    }          
                }

                scope.selectTraining = function (training) {
                   var registerTraining = function (){
                        var currentTrainingPersonDto = scope.pevc;
                        currentTrainingPersonDto.trainingSessionId = training.trainingSessionId;
                        currentTrainingPersonDto.trainingStatusId = trainingStatusIds.REGISTERED

                        trainingSvc.updateTrainingSession(currentTrainingPersonDto).then(function(){
                            trainingSvc.getCurrentUsersTrainingPersonDto().then(function(){
                                setRegistrationVariables();
                                sendPEVCAcceptTrainingEmails(training, currentTrainingPersonDto);
                                if(scope.isAdmin){
                                    trainingSvc.getPEVCTrainingSearch(scope.searchOptions);
                                    scope.registrationComplete = true;
                                }
                            });        
                        })
                    }

                    alertSvc.confirm("Are you sure you want to register for this training session?", registerTraining);
                }

                scope.getTrainingSessionMsg = function(){
                    var trainingSession = helperSvc.getFirstMatch(scope.trainingSessions, 'trainingSessionId', scope.pevc.trainingSessionId);
                    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };


                    if (trainingSession) {//should always be
                        var startDate = new Date(trainingSession.startDate);
                        var endDate = new Date(trainingSession.endDate);
                        return "You are registered for <em>" + trainingSession.sessionName + "</em>.<br/> The dates of your session are " + startDate.toLocaleDateString("en-US", options) + " through " + endDate.toLocaleDateString("en-US", options) + "."
                    }
                }

                function sendPEVCAcceptTrainingEmails(trainingSession, trainingPerson) {
                    var dateOptions = {year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
                    var startDate = new Date(trainingSession.startDate);
                    var trainDay2 = new Date(startDate);
                    trainDay2.setDate(trainDay2.getDate() + 1);
                    var trainDay3 = new Date(startDate);
                    trainDay3.setDate(trainDay3.getDate() + 2);

                    var messageParams = [
                        {
                            messageParameterName: 'SessionDate', messageParameterValue: startDate.toLocaleDateString("en-US", dateOptions)
                        },
                        {
                            messageParameterName: 'TrainingDay1', messageParameterValue: startDate.toLocaleDateString("en-US", dateOptions)
                        },
                        {
                                messageParameterName: 'TrainingDay2', messageParameterValue:  trainDay2.toLocaleDateString("en-US", dateOptions)
                        },
                        {
                            messageParameterName: 'TrainingDay3', messageParameterValue: trainDay3.toLocaleDateString("en-US", dateOptions)
                        },
                        {
                            messageParameterName: 'SessionTime', messageParameterValue: ("00" + startDate.getUTCHours()).slice(-2) + ':' + ("00" + startDate.getUTCMinutes()).slice(-2) + (startDate.getUTCHours() < 12 ? ' AM' : ' PM')
                        }
                    ];

                    var roles = [contactRoleTypes.EDUCATIONLIASON, contactRoleTypes.PEVRECRUITINGMANAGER, contactRoleTypes.TRAININGMANAGER];
                    membershipSvc.getSocietyContacts([trainingPerson.societyId], roles).then(function(data){
                        var trainingManager = helperSvc.getFirstMatch(data, 'roleId', contactRoleTypes.TRAININGMANAGER);
                        var firstContact = trainingManager ? trainingManager : data && data.length > 0 ? data[0] : {firstName: "", lastName: "", number: "", extension: ""};
                        var ccdRecipients = data && data.length > 0 ? data.map(function(rec){ return rec.emailAddress; }) : [];


                        ccdRecipients = ccdRecipients.reduce(function (a, b) {
                          if (a.indexOf(b) == -1) {
                            a.push(b)
                          }
                          return a;
                        }, []);

                        var societyRecipients = angular.copy(ccdRecipients);

                        ccdRecipients.push('training@abet.org');//training abet enail

                        messageParams.push.apply(messageParams, [{
                                messageParameterName: 'SocietyContactName', messageParameterValue: firstContact.firstName + " " + firstContact.lastName 
                        },{
                                messageParameterName: 'SocietyContactPhone', messageParameterValue:  firstContact.extension ? firstContact.number + " ext: " + firstContact.extension : firstContact.number
                        }])

                        messageSvc.buildAndSendMessage([trainingPerson.personId], ccdRecipients, messageTemplateTypes.PEVCREGISTERED, messageParams);



                        //Send to Liasons as well
                        var societyMessageParams = [
                            {
                                messageParameterName: 'PEVCName', messageParameterValue: currentUser.profile.firstName + " " + currentUser.profile.lastName
                            },
                            {
                                messageParameterName: 'TrainingDate', messageParameterValue: trainingSession.sessionName
                            },
                            {
                                messageParameterName: 'TrainingSociety', messageParameterValue: firstContact.abbreviatedName
                            }
                        ];


                        messageSvc.buildAndSendMessage(societyRecipients, [], messageTemplateTypes.PEVCACCEPTEDTRAININGFORSOCIETY, societyMessageParams);
                    });        
                }               

            }
        };
    });

}(angular.module('training')));

(function (module) {

    var addCommissionerCtrl = function ($scope, $uibModalInstance, roleId, currentSociety, isAlternate, isAdmin, commissionOptions,
                                        societyOptions, helperSvc, codeSvc, nominationSvc, alertSvc, commissionTypes) {
        var model = $scope;

        //model.parent = parent;

        model.roleId = roleId; // required to create a new nomination 
        model.commissionOptions = commissionOptions;
        model.societyOptions = societyOptions;
        model.isAlternate = isAlternate || false;
        model.isAdmin = isAdmin != null ? isAdmin : oauth.isAdmin();

        model.title = model.isAlternate ? 'Add Alternate' : 'Add Commissioner';

        model.currentInformation = {
            commission: null,
            society: currentSociety,
            commissioner: null ,
            comments: null,
        }

        model.isCurrentCommissionerValid = function () {
            var commissioner = model.currentInformation.commissioner;
            // Need to have selected a person
            if (!commissioner)
                return false;
            // If selection is eligible, then proceed
            if ((!model.isAlternate && commissioner.isNominationEligible) ||
                (model.isAlternate && commissioner.isAlternateEligible))
                return true;
            // Allow override of ineligibility if user is an admin and person is a new nomination and not eligible for renewal.
            if (model.isAdmin &&
                !commissioner.isNewNominee &&
                !commissioner.isCurrentAlternate &&
                (!commissioner.isCurrentCommissioner || !commissioner.isRenewable))
                return true; 
        }
         
        model.save = function () {
            var commissionerNomination = {
                commissionerNominationId: 0,
                cycleYear: helperSvc.getCommissionerNominationYear(),
                roleId: model.roleId,
                commissionId: model.currentInformation.commission.id,
                societyId: model.currentInformation.society.codeKey,
                personId: model.currentInformation.commissioner.personId,
                commissionerNominationStatusId: nominationSvc.nominationStatuses.NOMINATED_AS_NEW,
                societyNote: model.currentInformation.comments,
                adminNote: null,
                submittedDate: model.isAdmin ? new Date() : null,
                isApproved: null,
            }

            nominationSvc.create(commissionerNomination).then(
                function () {
                    alertSvc.addAlertSuccess("Commissioner successfully nominated");
                },
                function (error) {
                    console.log(error);
                    alertSvc.addAlertWarning('Commissioner could not be nominated at this time.');
                });

            model.close();
        };
 
        model.close = function () {
            $uibModalInstance.close();
        };

        (model.activate = function activate() {
            if (!model.commissionOptions) {
                model.commissionOptions = commissionTypes;
            }
            if (!model.societyOptions && model.isAdmin) {
                codeSvc.getMemberAndSupportingSocieties().then(function (data) {
                    model.societyOptions = helperSvc.getResults(data);
                });
            }
        })();
    };

    module.controller('addCommissionerCtrl', addCommissionerCtrl);

}(angular.module('nomination')));
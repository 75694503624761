(function (module) {

    var assignFacilitatorCtrl = function ($scope, alertSvc, commissionAbbreviations, helperSvc, pevc, searchOptions, $uibModalInstance, trainingSvc, isObserver) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Assign Facilitator';
        model.searchOptions = searchOptions;
        model.facilitatorFilterOptions = { trainingYear: searchOptions.trainingYear, trainingId: searchOptions.trainingId, trainingSessionId: pevc.trainingSessionId };
        model.currentPevc = pevc;
        model.commissionNames = commissionAbbreviations;
        model.selectedFacilitator = { facilitator: null };
        model.firstSave = true;

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.firstSave) {
                model.firstSave = false;

                if (isObserver) {
                    trainingSvc.addPersonFacilitators(model.selectedFacilitator.facilitator.trainingFacilitatorId, [pevc.trainingPersonId]).then(function () {
                        alertSvc.addAlertSuccess("Facilitator successfully assigned.");
                        trainingSvc.getTrainingObservers(searchOptions);
                        $uibModalInstance.close();
                    });
                } else {
                    trainingSvc.addPersonFacilitators(model.selectedFacilitator.facilitator.trainingFacilitatorId, [pevc.trainingPersonId]).then(function () {
                        trainingSvc.getPEVCTrainingSearch(model.searchOptions)
                        alertSvc.addAlertSuccess("Facilitator succesfully assigned.");
                        $uibModalInstance.close();
                    });
                }
               
            }
        }

        var activate = function () {
            var dataArray = [];
            var trainingFacilitators = {
                dataHolder: model,
                dataLocationName: 'facilitatorList',
                svcCallback: trainingSvc.getTrainingFacilitators,
                svcCallbackArguments: [model.facilitatorFilterOptions],
                odataResource: true
            }
            dataArray.push(trainingFacilitators);

            helperSvc.getData(dataArray).then(function () {
                model.data = trainingSvc.data;
                model.dataIsReady = true;
            });
        }();
    };

    module.controller('assignFacilitatorCtrl', assignFacilitatorCtrl);

}(angular.module('training')));
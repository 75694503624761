(function (module) {
    var rrSubmitCtrl = function ($uibModal, $state, helperSvc, alertSvc, readinessSvc, readinessValidationSvc, typeConstSvc, $filter, codeSvc,messageSvc,messageTemplateTypes) {
        var model = this;
        var readinessEmailAddress = 'ReadinessReview@abet.org';

        model.data = readinessSvc.data;
        model.readinessStatuses = typeConstSvc.getReadinessStatuses();
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.openSubmissionInstructions = readinessSvc.openSubmissionInstructions;

        model.isSubmissionReady = function () {
            var signedText = model.data.selectedRR.signedText;
            var isSubmitting = model.isSubmitting;
            return !isSubmitting && signedText && signedText.trim().length > 0;
        } 

        model.currentStatusIs = function (status) {
            var readinessProcessTrackingDtos = model.data.selectedRR.readinessProcessTrackingDtos;

            var currentStatusIs =
                (readinessProcessTrackingDtos != null &&
                 readinessProcessTrackingDtos.length > 0 &&
                 readinessProcessTrackingDtos[0].readinessStatusId === status);

            return currentStatusIs;
        }

        model.submissionDate = function () {
            var readinessProcessTrackingDtos = model.data.selectedRR.readinessProcessTrackingDtos;
            var submissionDate = null;
            if (readinessProcessTrackingDtos != null && readinessProcessTrackingDtos.length > 0) {
                var submissionProcessTracking = readinessProcessTrackingDtos.find(function (processTracking) {
                    return processTracking.readinessStatusId == model.readinessStatuses.SUBMITTED_WAITING_FOR_ACCEPTANCE;
                });
                if (submissionProcessTracking != null) {
                    submissionDate = submissionProcessTracking.commitDate;
                }
            }

            return submissionDate;
        }

        model.saveProgress = function () {
            var noStoredData = true;
            readinessSvc.update(model.data.selectedRR, noStoredData).then(function () {
                readinessValidationSvc.invokeValidation();
            });
        }

        model.submit = function () {
            readinessValidationSvc.validate(model.data.selectedRR).then(function (results) {
                if (results.length === 0) {
                    var confirmationMsg = 'Are you sure you want to submit this readiness review? This procedure is irreversible.';

                    alertSvc.confirm(confirmationMsg, doSubmission);
                } else {
                    alertSvc.openModalAlert("The readiness review you are trying to submit is not valid. Please look at the errors in each tab at the top of the page and fix them.");
                }
            });

            function doSubmission() {
                model.isSubmitting = true;
                addSubmittedStatus();
                addOrganizationCommissionDtos();
                readinessSvc.update(model.data.selectedRR).then(
                    function () {
                        alertSvc.addAlertSuccess("Readiness Review succesfully submitted to ABET");
                        model.isSubmitted = true;

                        var messageTemplate = messageTemplateTypes.RRSUBMISSIONCONFIRMATION;
                        var messageParamaters = [{
                            messageParameterName: "CurrentYear",
                            messageParameterKey: null,
                            messageParameterValue: helperSvc.formatDateWithCurrent(null).getFullYear().toString()
                        }, {
                            messageParameterName: "InstitutionName",
                            messageParameterKey: null,
                            messageParameterValue: model.data.selectedRR.organizationJson.organizationName
                        }];
                        messageSvc.buildAndSendMessage([model.data.selectedRR.contactPersonId], [readinessEmailAddress], messageTemplate, messageParamaters);
                    },
                    function () {
                        model.isSubmitting = false;
                        removeSubmittedStatus();
                        alertSvc.addAlertWarning("Readiness Review could not be submitted at this time.");
                    });
            }
        }

        function addOrganizationCommissionDtos() {
            var organizationCommissionDtos = model.data.selectedRR.commissionIds.map(function (id) {
                return {
                    organizationCommissionId: 0,
                    organizationId: model.data.selectedRR.organizationId,
                    commissionId: id,
                    abbreviatedCommissionName: getCommissionName(id),
                    ngrYear: model.data.selectedRR.requestYear,
                    accountNumber: null
                };
            });
            model.data.selectedRR.organizationJson.organizationCommissions = organizationCommissionDtos;
        };

        function addSubmittedStatus() {
            var readinessProcessTrackingDtos = model.data.selectedRR.readinessProcessTrackingDtos;

            if (Array.isArray(readinessProcessTrackingDtos)) {
                var submittedStatus = {
                    readinessId: model.data.selectedRR.readinessId,
                    readinessStatusId: model.readinessStatuses.SUBMITTED_WAITING_FOR_ACCEPTANCE,
                    commitDate: new Date(),
                    note: null
                };
                readinessProcessTrackingDtos.unshift(submittedStatus);
            }
        }

        function removeSubmittedStatus() {
            var readinessProcessTrackingDtos = model.data.selectedRR.readinessProcessTrackingDtos;
            if (Array.isArray(readinessProcessTrackingDtos) &&
                readinessProcessTrackingDtos.length > 0 &&
                readinessProcessTrackingDtos[0].readinessStatusId === model.readinessStatuses.WAITING_FOR_ABET_ACKNOWLEDGEMENT) {

                readinessProcessTrackingDtos.shift();
            }
        }

        function getCommissionName(id) {
            var commission = model.commissions.find(function (item) {
                return item.codeKey === id;
            });
            return commission ? commission.codeName : "";
        }

        model.isOtherOptionValue = readinessSvc.isOtherOptionValue;

        var activate = function () {
            model.isSubmissionRequired = !model.currentStatusIs(model.readinessStatuses.READINESS_REVIEW_NOT_REQUIRED);
            model.isReadyToSubmit = model.currentStatusIs(model.readinessStatuses.WAITING_FOR_READINESS_REVIEW_SUBMISSION);
            model.isSubmitted = !model.currentStatusIs(model.readinessStatuses.WAITING_FOR_ABET_ACKNOWLEDGEMENT) &&
                                !model.currentStatusIs(model.readinessStatuses.WAITING_FOR_READINESS_REVIEW_SUBMISSION);

            codeSvc.getCommissions().then(function (data) {
                model.commissions = helperSvc.getValue(data);
            });
        }();

    };

    module.controller('rrSubmitCtrl', rrSubmitCtrl);

}(angular.module('readinessReview')));
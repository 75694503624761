(function (module) {

    var addFacilitatorCtrl = function ($scope, $uibModalInstance, alertSvc, trainingSvc, trainingTypeIds, societyOptions, searchOptions, oauth, codeSvc, helperSvc, contactRoleTypes) {
        var model = $scope;

        model.dataIsReady = false;
        model.title = 'Add Facilitator';
        model.currentInformation = {};
        model.trainingSessions = trainingSvc.data.trainingSessions;
        model.isAdmin = oauth.isAdmin();

        model.cancel = function () {
            $uibModalInstance.close();
        };

        model.addFacilitator = function () {
            if(alreadyFacilitatorForThisTraining())
                return alertSvc.addModalAlertWarning("Facilitator already assigned to this training");

            var facilitatorDto = {
                trainingSessionId: model.currentInformation.trainingSessionId,
                personId: model.currentInformation.facilitator.personId,
                facilitatorType: model.currentInformation.facilitatorType,
                commissionId: model.currentInformation.commissionId
            }

            trainingSvc.addTrainingFacilitator(facilitatorDto).then(function () {
                trainingSvc.getTrainingFacilitators(searchOptions);
                alertSvc.addModalAlertSuccess("Facilitator succesfully added.");
                $uibModalInstance.close();
            })

        }
    
        function alreadyFacilitatorForThisTraining(){
            var filteredTrainings = helperSvc.getFilteredArray(trainingSvc.data.trainingFacilitatorList, 'trainingSessionId', model.currentInformation.trainingSessionId, true);

            return helperSvc.getFirstMatch(filteredTrainings, 'personId', model.currentInformation.facilitator.personId) !== null;
        }

        var activate = function () {
            model.currentInformation.facilitatorType = null;
            model.currentInformation.facilitator = null;
            model.currentInformation.trainingSessionId = null;
            model.currentInformation.commissionId = null;

            codeSvc.getCommissions().then(function (data) {
                model.trainingCommissions = helperSvc.getResults(data);
            });

            model.dataIsReady = true;
        }();
    };

    module.controller('addFacilitatorCtrl', addFacilitatorCtrl);

}(angular.module('training')));